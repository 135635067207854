import '../styles/Home.css';
import React, { useState, useEffect } from 'react';
import Contact from '../components/contact/Contact'
import Project from '../components/project/Project'
/* import Footer from '../components/footer/Footer' */
import html from '../images/html.png'
import git from '../images/github.png'
import javascript from '../images/java-script.png'
import sass from '../images/sass.png'
import react from '../images/react.png'
import node from '../images/node-js.png'
import arrow from '../images/up-arrow.png'
import mongodb from '../images/mongodb.png'
import express from '../images/express.png'
import figma from '../images/figma.png'

function Home(){

    const skills = [
        {
            name: "HTML",
            img: html
        },
        {
            name: "SCSS",
            img: sass
        },
        {
            name: "Javascript",
            img: javascript
        },
        {
            name: "React",
            img: react
        },
        {
            name: "Node.js",
            img: node
        },
        {
            name: "Express",
            img: express
        },
        {
            name: "MongoDB",
            img: mongodb
        },
        {
            name: "Github",
            img: git
        },
        {
            name: "Figma",
            img: figma
        },
    ]

    // Adapte le degré du linear-gradient en fonction du scroll ( différente source de lumière )
    window.addEventListener('scroll', function() {
        const scrollTop = window.scrollY;
        const gradientDegree = scrollTop / (document.body.scrollHeight - window.innerHeight) * 360;
    
        const gradientColors = `linear-gradient(${gradientDegree}deg, rgba(33,33,33,1) 0%, rgba(71,71,71,1) 30%, rgba(124,124,124,1) 60%, rgba(255,255,255,1) 100%)`;
    
        // Sélectionner les éléments avec les classes .page-wrapper et .contact-wrapper
        var elements = document.querySelectorAll('.page-wrapper, .contact-wrapper');
    
        // Parcourir chaque élément sélectionné et appliquer le style
        elements.forEach(function(element) {
            element.style.background = gradientColors;
        });
    });

    window.addEventListener('scroll', function() {
        const scrollTop = window.scrollY;
        const boxShadowX = -8; // Décalage horizontal
        const boxShadowY = 10 + scrollTop * 0.5; // Décalage vertical en fonction du scroll
        const boxShadowBlur = 20; // Flou du box shadow
        const boxShadowColor = 'rgb(47, 47, 47)'; // Couleur du box shadow
    
        const boxShadow = `${boxShadowX}px ${boxShadowY}px ${boxShadowBlur}px ${boxShadowColor}`;
    
        // Sélectionner les éléments sur lesquels appliquer le box shadow
        var elements = document.querySelectorAll('.page-wrapper, .contact-wrapper');
    
        // Parcourir chaque élément sélectionné et appliquer le style
        elements.forEach(function(element) {
            element.style.boxShadow = boxShadow;
        });
    });
    
   
    // Hover des compétences
    const [openIndex, setOpenIndex] = useState(null);
    const logoHover = (index) => {
        setOpenIndex(index);
    };
    const logoLeave = () => {
        setOpenIndex(null);
    };  

    // Gère l'affichage du bouton ' Retour en haut '
    const [showButton, setShowButton] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            // Vérifie si l'utilisateur a fait défiler jusqu'en bas de la page
            if (window.pageYOffset > window.innerHeight / 1.5) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        // Ajoute un écouteur d'événements de défilement lors du montage du composant
        window.addEventListener('scroll', handleScroll);

        // Nettoie l'écouteur d'événements lors du démontage du composant
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return(
        <div className='home-container'>
            <div className="wrapper">
                <div className="intro">
                            <h1 id='welcomeTitle'>Bienvenue</h1>
                        <div className='intro-text'>
                            <span className='intro-span'>Je suis Enzo Vincent<br/></span>
                            <span className='intro-span'>Développeur Fullstack</span>
                        </div>
                        <div className="links">
                            <a href="#work"><span className='link'>Mon travail</span></a>
                            <a href="#skills"><span  className='link'>Compétences</span></a>
                            <a href="#contact"><span  className='link'>Contact</span></a>
                        </div>
                </div>
                {showButton && (
                    <img src={arrow} alt="Retour en haut" className={`scroll-to-top-button ${showButton ? 'visible' : ''}`} onClick={scrollToTop} />
                )}
            </div>
            <div id='work'>
                <Project />
            </div>
            <div id='skills' className="skills-wrapper">
                <div className="skills-title">
                    <span>C</span>
                    <span>O</span>
                    <span>M</span>
                    <span>P</span>
                    <span>E</span>
                    <span>T</span>
                    <span>E</span>
                    <span>N</span>
                    <span>C</span>
                    <span>E</span>
                    <span>S</span>
                </div>

                <div className="skills-block">
                    <div className={'skills-container skills-container-visible'}>
                        {skills.map((skill, index) => (
                            <div key={index} onMouseEnter={() => logoHover(index)} onMouseLeave={logoLeave} className={`skill ${openIndex === index ? 'open-logo' : ''}`}>
                                    <img key={index} src={skill.img} alt={skill.name}/>
                                <div className="skill-description">
                                    <h3>{skill.name}</h3>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div id='contact' className="contact-wrapper">
                <Contact />
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default Home